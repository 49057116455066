
/* width */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow:    inset 0 0 1px rgb(255, 255, 255);
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background:    darkgrey;
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: grey;
}
