$header-color:           rgba(0, 0, 0, 0.88);
$header-box-color:       rgba(255, 255, 255, 0.7);
$header-box-hover-color: rgba(255, 255, 255, 0.4);

$active-color:   #00D93D;
$disabled-color: #e6e6e666;
$focus-color:    #daffda;
$invalid-color:  #FF0000;

$status-positive: #00E469;
$status-negative: #FF0000;

$basic-white: #FFFFFF;
$basic-grey:  #E6E6E6;