.movie-details-container {
    width:               100%;
    background-repeat:   no-repeat;
    background-position: center;
    background:          rgb(201 240 255 / 80%);
    display:             flex;
    padding:             10px;
    box-shadow:          inset 0 0 0 1000px rgba(23, 59, 85, 0.5);
}

.movie-info {
    display:          flex;
    flex-direction:   column;
    width:            100%;
    height:           100%;
    background-color: rgba(255, 255, 255, 0.6);

    padding: 15px;

    & * {
        margin-top: 0;
    }

    & .movie-title {
        margin-bottom: 0;
    }

    & .tagline {
        margin-bottom: 10px;
    }
}