#page-header {
    position:        sticky;
    top:             0;
    z-index:         1;
    display:         flex;
    align-items:     center;
    justify-content: space-between;

    flex-wrap:     wrap;
    height: 10vh
}

.header-part {
    display:     flex;
    align-items: center;
}