@import './scrollbar.css';

html,
body,
#root {
  height: 100%;
}

html {
  background-color: rgb(131, 181, 255);
}

body {
  font-family: 'Open Sans', sans-serif;
  max-width:   1024px;
  margin:      0 auto;
}

#root {
  display:        flex;
  flex-direction: column;
}